<template>
  <div class="page-index">
<Navbar title="场景" :length="list.length" source="scene" :loading="loading"></Navbar>
<img src="@/assets/images/bg_gradient.png" alt="" class="bg">
    <div class="m-lr-40">
      <RoleCard :list="defaultList"  source="scene" :needAva="false" class="pre" :needStatus="false"></RoleCard>
      <p class="basic-font" v-if="!loading">我的话题</p>
      <van-list
        :finished="finished"
        finished-text=""
        @load="onLoad"
      >
      <RoleCard :list="list" source="scene"  :needAva="false" :loading="loading" :length="list.length" text="暂无话题"></RoleCard>
      </van-list>
    </div>
    <Tabbar activeIndex="1"></Tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      defaultList:[
        // {
        //   title:"人生旅程"
        // }
        
      ],
      list: [],
      loading: false,
      finished: false,
      keywords:''
    };
  },
  created(){
this.getTopicList()
  },
  methods: {
  async  getTopicList(){
    this.loading=true
     const res= await this.$api.chat.getAskList(localStorage.getItem("id"))
     this.defaultList=res.data[0]
     this.list=res.data[1]??[]
    this.loading=false

    },
    onLoad() {
      this.finished = true;
    },
  },
};
</script>
<style lang="less" scoped>
.text-overflow{
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.m-lr-40 {
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
.basic-font {
  font-size: 0.32rem;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
}
.card {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.4rem;
  box-sizing: border-box;
  width: 6.68rem;
  height: 1.2rem;
  background: #ffffff;
  box-shadow: 0 0.04rem 0.2rem 0 rgba(184, 185, 188, 0.3);
  border-radius: 0.2rem;
  font-size: 0.32rem;
  margin-bottom: .3rem;
  .group {
    width: 100%;
    display: flex;
    align-items: center;
    .avater {
      margin-right: 0.2rem;
      width: .64rem;
      height: .64rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding:.04rem .04rem;
      box-sizing: border-box;
      background: #ffffff;
      border-width: 2px;
      border-style: solid;
      font-size: .18rem;
      font-weight: 400;
      text-align: center;
      border-radius: 50%;
    }
  }
  .van-icon{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .4rem;
  }
}
.page-index {
  min-height: 100vh;
  padding-bottom: 1.2rem;
  background-color: #f5f7fb;
  position: relative;
  .pre {
    position: relative;
    z-index: 1;
    margin: 0.6rem 0;
    font-size: 0.32rem;

    span {
      font-size: 0.32rem;
      font-family: PingFang SC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
    }
  }
  p {
    position: relative;
    padding-left: 0.08rem;
    margin-bottom: .4rem;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 0.04rem;
      height: 0.24rem;
      transform: translateY(50%);
      background: #294bf9;
      border-radius: 0.2rem;
    }
  }
 
}

</style>